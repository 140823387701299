<template>
  <div>
    <h2>Dobrodošli na Inventar app</h2>
    <lottie-animation path="data.json" :height="500" />
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
  name: "dashboard",
  components: {
    LottieAnimation,
  },
};
</script>

<style scoped></style>
